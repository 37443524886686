/**
 * Convert absolute URL to relative (strip protocol and hostname).
 *
 * @param { string } url
 * @returns { string|undefined }
 */
export function convertToRelativeUrl(url?: string): string | undefined {
  if (!url) {
    return;
  }
  return url.replace(/https?:\/\/[^/]+/i, '');
}
